/************** feature-wrap ***************/
.feature-area {
  background: #E8E6CA;
  box-shadow: 0px 4px 50px rgba(0, 35, 90, 0.1);
  border-radius: 20px;
  padding: 40px;
  position: relative;
  top: -60px;
  z-index: 2;
}
.feature-wrap {
  padding: 30px 40px 35px;
  border-radius: 10px;
  &.bg-pink {
    background: rgba(250, 67, 24, 0.1);
  }
  &.bg-ash {
    background: rgba(8, 12, 36, 0.1);
  }
  &.bg-sky {
    background: rgba(0, 133, 239, 0.1);
  }
  .icon {
    background: var(--main-color);
    height: 60px;
    width: 60px;
    line-height: 60px;
    display: inline-flex;
    border-radius: 50%;
    position: relative;
    margin: 5px 0 22px 5px;
    &:after {
      position: absolute;
      content: "";
      left: -5px;
      top: -5px;
      background: var(--heading-color);
      height: 60px;
      border-radius: 50%;
      width: 60px;
      z-index: -1;
    }
    img {
      margin: auto;
      width: auto;
    }
  }
  p {
    margin-top: 12px;
    margin-bottom: 0;
  }
}
.feature-slider {
 
  .slick-next {
    left: auto;
    right: 80px;
    z-index: 9;
    top: -85px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #E8E6CA;
    color: var(--main-color);
    padding: 18px;
    box-shadow: 0px 8px 20px rgba(7, 36, 95, 0.15);
    transition: all 0.2s linear;
    &:hover {
      background: var(--main-color);
      color: #E8E6CA;
    }
  }
  .slick-prev {
    left: auto;
    right: 0;
    z-index: 9;
    top: -85px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #E8E6CA;
    color: var(--main-color);
    padding: 18px;
    box-shadow: 0px 8px 20px rgba(7, 36, 95, 0.15);
    transition: all 0.2s linear;
    &:hover {
      background: var(--main-color);
      color: #E8E6CA;
    }
  }
}
/************** about-thumb-wrap ***************/
.about-area-2 {
  .about-inner-wrap {
    margin-right: 64px;
  }
  .about-thumb-wrap .img-1 {
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
  }
  .about-thumb-wrap .img-2 {
    margin-left: -58px;
  }
  .about-thumb-wrap .img-3 {
    margin-left: -168px;
    margin-bottom: -73px;
  }
  .about-thumb-wrap .exprience-wrap {
    right: auto;
    top: auto;
    bottom: -88px;
    left: -56px;
  }
}
.about-thumb-wrap {
  position: relative;
  .img-1 {
    position: absolute;
    left: -39px;
    bottom: -53px;
    z-index: -1;
  }
  .img-3 {
    margin-left: -215px;
    margin-bottom: -63px;
    vertical-align: bottom;
  }
  .exprience-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    right: -20px;
    top: 17px;
    img {
      margin-bottom: -15px;
    }
    .details {
      background: url("~/public/assets/img/about/shape-2.png");
      background-size: cover;
      padding: 45px 30px;
      background-position: center;
      h1 {
        font-size: 64px;
        font-weight: 900;
        color: var(--main-color);
        margin-bottom: 0;
        line-height: 83px;
      }
      p {
        font-weight: 600;
        color: var(--heading-color);
        line-height: 19px;
      }
    }
  }
}
.about-inner-wrap {
  margin-left: 58px;
  .section-title {
    .content {
      margin-top: 10px;
      padding-left: 10px;
      font-size: 20px;
      font-weight: 500;
      color: var(--heading-color);
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    margin-top: 25px;
    padding-top: 30px;
    .btn {
      margin-top: 0;
    }
    .author-wrap {
      margin: 0 auto;
      text-align: center;
      display: flex;
      align-items: center;
      img {
        flex: none;
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        border-top: 1px solid #e4e4e4;
        margin-top: 4px;
        font-size: 14px;
        font-weight: 500;
        color: #4d5765;
      }
    }
    .video-wrap {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: var(--heading-color);
      margin-left: 100px;
      span {
        margin-left: 10px;
        text-decoration: underline;
      }
      .video-play-btn {
        height: 45px;
        width: 45px;
        line-height: 45px;
        &:after {
          height: 55px;
          width: 55px;
          top: -5px;
          left: -5px;
          border: 0;
          background: rgba(250, 67, 24, 0.3);
        }
        i,
        svg {
          font-size: 14px;
          margin-left: 2px;
        }
      }
    }
  }
}
.single-about-wrap {
  display: flex;
  align-items: center;
  margin-top: 9px;
  .icon {
    flex: none;
    margin-right: 15px;
  }
  h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
  }
}
.list-inner-wrap {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    display: flex;
    align-items: center;
    color: var(--heading-color);
    font-weight: 500;
    + li {
      margin-top: 14px;
    }
    img,
    i,
    svg {
      margin-right: 8px;
    }
  }
}
/*******why-choose-us-area********/
.wcu-area {
  padding: 330px 0 227px;
  .img-1 {
    position: absolute;
    top: 69px;
    left: 37px;
  }
  .img-2 {
    position: absolute;
    bottom: -28px;
    right: 0;
  }
}
.wcu-area-2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 168px;
}
.single-wcu-wrap {
  display: flex;
  margin-top: 22px;
  .icon {
    position: relative;
    flex: none;
    padding: 5px 0 0 5px;
    z-index: 0;
    margin-right: 15px;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: transparent;
      height: 50px;
      width: 50px;
      border: 1px solid var(--main-color);
      border-radius: 50%;
      z-index: -1;
    }
  }
  .details {
    h6 {
      color: #E8E6CA;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }
    p {
      color: #d6d6d6;
      line-height: 26px;
      margin-bottom: 0;
    }
  }
  &.style-2 {
    .icon {
      height: 50px;
      width: 50px;
      background: var(--main-color);
      border-radius: 50%;
      line-height: 50px;
      text-align: center;
      z-index: 1;
      padding: 0;
      margin-top: 10px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: var(--main-color);
        z-index: -1;
        border: 0;
        border-radius: 50%;
      }
      &:after {
        background: #E8E6CA;
        z-index: -2;
        top: -5px;
        left: -5px;
        border: 0;
      }
    }
  }
}

/*****fact-counter-area******/
.fact-area {
  .fact-counter-area {
    transform: translate(0px, -120px);
    margin-bottom: -120px;
  }
}
.fact-counter-area {
  border-top: 5px solid var(--main-color);
  padding: 39px 45px 11px;
  transform: translate(0px, -114px);
  margin-bottom: -114px;
}
.single-fact-wrap {
  position: relative;
  margin-bottom: 30px;
  padding-left: 15px;
  &:after {
    content: "";
    position: absolute;
    right: -15px;
    top: 50%;
    height: 140px;
    width: 4px;
    transform: translate(0, -50%);
    background: #E8E6CA;
  }
  h2 {
    color: #E8E6CA;
    font-weight: 700;
    font-size: 44px;
    line-height: 57px;
    margin-bottom: 8px;
  }
  h5 {
    line-height: 31px;
    color: #E8E6CA;
    margin-bottom: 10px;
  }
  p {
    line-height: 26px;
    color: #d6d6d6;
    margin-bottom: 0;
  }
}
/********team-slider********/
.team-area {
  position: relative;
  z-index: 9;
  .team-slider {
    .slick-slider {
      .item {
        padding: 0 22px;
      }

      .slick-next {
        left: auto;
        right: 80px;
        z-index: 9;
        top: -85px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #E8E6CA;
        color: var(--main-color);
        padding: 18px;
        box-shadow: 0px 8px 20px rgba(7, 36, 95, 0.15);
        transition: all 0.2s linear;
        &:hover {
          background: var(--main-color);
          color: #E8E6CA;
        }
      }
      .slick-prev {
        left: auto;
        right: 0;
        z-index: 9;
        top: -85px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #E8E6CA;
        color: var(--main-color);
        padding: 18px;
        box-shadow: 0px 8px 20px rgba(7, 36, 95, 0.15);
        transition: all 0.2s linear;
        &:hover {
          background: var(--main-color);
          color: #E8E6CA;
        }
      }
    }
  }
}

.single-team-wrap {
  position: relative;
  background: #E8E6CA;
  box-shadow: 0px 10px 12px rgba(7, 36, 95, 0.06);
  border-radius: 10px;
  text-align: center;
  transition: $transition;
  .thumb {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: var(--heading-color);
      mix-blend-mode: soft-light;
      opacity: 0.6;
      border-radius: 10px 10px 0px 0px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &:hover {
    background: var(--main-color);
    .details {
      p,
      h5 {
        color: #E8E6CA;
      }
      .hover-icon {
        background: #E8E6CA;
        color: var(--main-color);
        &:before {
          background: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  .details {
    padding: 27px 30px 28px;
    transition: $transition;
    position: relative;
    h5 {
      line-height: 35px;
      transition: $transition;
      margin-bottom: -2px;
    }
    p {
      line-height: 24px;
      transition: $transition;
      margin-bottom: 0;
    }
    .hover-icon {
      background: var(--main-color);
      height: 40px;
      width: 40px;
      border-radius: 50%;
      line-height: 40px;
      color: #E8E6CA;
      position: absolute;
      right: 30px;
      top: -50%;
      transition: $transition;
      transform: translate(0, 50%);
      margin-top: 15px;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        height: 60px;
        width: 60px;
        background: rgba(250, 67, 24, 0.4);
        border-radius: 50%;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        z-index: -1;
        transition: $transition;
      }
      i,
      svg {
        transition: $transition;
      }
      &:hover {
        .fa-plus {
          transform: rotate(45deg);
          color: var(--main-color);
        }
        .social-area {
          opacity: 1;
        }
      }
    }
    .social-area {
      transition: $transition;
      position: absolute;
      right: 50%;
      top: 0;
      transform: translate(50%, -100%);
      transition: all 0.3s ease-in;
      margin-bottom: 0;
      padding: 0;
      margin-top: -20px;
      opacity: 0;
      li {
        display: block;
        margin: 0;
        + li {
          margin-top: 10px;
        }
        a {
          background: #E8E6CA;
          box-shadow: 0px 4px 15px rgba(7, 36, 95, 0.15);
          width: 40px;
          line-height: 42px;
          height: 40px;
          color: var(--paragraph-color);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          transition: $transition;
          i,
          svg {
            transition: none;
          }
          &:hover {
            background: var(--main-color);
            box-shadow: 0px 8px 19px rgba(255, 76, 19, 0.3);
            color: #E8E6CA;
          }
        }
      }
    }
  }
}
.team-slider {
  .owl-nav {
    button {
      transform: translate(0, -50%);
      top: 50%;
      position: absolute;
    }
    .owl-prev {
      left: -90px;
    }
    .owl-next {
      position: absolute;
      right: -90px;
    }
  }
}
/*******call-to-contact-area********/
.call-to-contact-area {
  margin-top: -120px;
  position: relative;
  z-index: 0;
  img {
    position: absolute;
    width: 73.5%;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .section-title {
    .subtitle {
      background-size: 98% 100%;
      // background-color: #14448c;
    }
  }
}
.cta-inner-wrap {
  background:#244855;
  padding: 40px 50px;
}
.single-cta-wrap {
  display: flex;
  margin-top: 24px;
  align-items: center;
  margin-bottom: 41px;
  margin-left: 14px;
  .icon {
    width: 50px;
    height: 50px;
    flex: none;
    background: var(--main-color);
    line-height: 52px;
    text-align: center;
    color: #E8E6CA;
    border-radius: 50%;
    font-size: 20px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      background: #fa4318;
      opacity: 0.3;
      height: 76px;
      width: 76px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
  .details {
    margin-left: 33px;
    h6 {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #E8E6CA;
      margin-bottom: 5px;
    }
    h3 {
      font-weight: 700;
      font-size: 30px;
      line-height: 39px;
      color: var(--main-color);
      margin-bottom: 0;
    }
  }
}
/*********testimonial-area**********/

.testimonial-slider {
  .slick-slider {
    .item {
      padding: 0 22px;
    }

    .slick-next {
      left: auto;
      right: 80px;
      z-index: 9;
      top: -85px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #E8E6CA;
      color: var(--main-color);
      padding: 18px;
      box-shadow: 0px 8px 20px rgba(7, 36, 95, 0.15);
      transition: all 0.2s linear;
      &:hover {
        background: var(--main-color);
        color: #E8E6CA;
      }
    }
    .slick-prev {
      left: auto;
      right: 0;
      z-index: 9;
      top: -85px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #E8E6CA;
      color: var(--main-color);
      padding: 18px;
      box-shadow: 0px 8px 20px rgba(7, 36, 95, 0.15);
      transition: all 0.2s linear;
      &:hover {
        background: var(--main-color);
        color: #E8E6CA;
      }
    }
  }
}
.testimonial-area-style-02 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 100px 0;
  .review-area {
    .testimonial-slider-two {
      display: block;
      .slick-slide {
        .single-review-item {
          padding: 10px 20px;
          .inner {
            margin-top: 30px;
            width: 100%;
            display: inline-block;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1019607843);
            padding: 40px 30px;
            border-radius: 10px;
            .review-head {
              display: flex;
              gap: 10px;
              .thumb {
                border-radius: 5px;
                overflow: hidden;
              }
              .author-details {
                h5 {
                  margin-bottom: -5px;
                }
                span {
                }
                .rating {
                  margin: 0;
                  padding: 0;
                  list-style: none;
                  display: flex;
                  gap: 5px;
                  svg {
                    color: var(--main-color);
                  }
                }
              }
            }
            .desciption {
              margin-top: 15px;
              p {
                font-size: 15px;
              }
            }
          }
        }
      }
      .slick-next,
      .slick-prev {
        color: var(--main-color);
      }
    }
  }
}
.single-testimonial-wrap {
  background: rgba(8, 12, 36, 0.05);
  border-radius: 5px;
  padding: 0 34px 40px 40px;
  margin-top: 60px;
  .icon {
    transform: translate(0px, -39px);
    margin-bottom: -9px;
    img {
      width: auto;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #4d5765;
    border-left: 3px solid var(--main-color);
    padding-left: 7px;
    margin-bottom: 0;
  }
  .client-wrap {
    margin-top: 29px;
    display: flex;
    align-items: center;
    .thumb {
      margin-right: 10px;
      flex: none;
      img {
        width: auto;
      }
    }
    .details {
      h5 {
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 3px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #4d5765;
        padding-left: 0;
        border: 0;
      }
    }
  }
}
/***********portfolio area**********/
.portfolio-area {
  background-position: center !important;
  background-size: cover !important;
  .section-title {
    margin-bottom: 50px;
  }
  .slick-slider {
    .slick-slide {
      transition: $transition;
      .single-portfolio-wrap {
        z-index: 9;
        transition: $transition;
        margin: 84px 0;
        position: relative;
        .portfolio-details {
          position: absolute;
          left: 0;
          bottom: 0;
          width: -webkit-fill-available;
          background: #E8E6CA;
          border-radius: 5px;
          margin: 20px;
          padding: 20px 23px 16px 22px;
          display: flex;
          align-items: center;
          opacity: 0;
          transition: $transition;
          .details {
            p {
              font-weight: 500;
              font-size: 10px;
              line-height: initial;
              color: var(--main-color);
              margin-bottom: 9px;
            }
            h4 {
              font-size: 17px;
              margin-bottom: 0;
            }
          }
          .icon {
            height: 28px;
            width: 28px;
            background: var(--main-color);
            border-radius: 5px;
            text-align: center;
            line-height: 30px;
            color: #E8E6CA;
            margin-left: auto;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &.slick-center {
        position: relative;
        z-index: 9;
        transform: scale(1.7);
        .portfolio-details {
          opacity: 1;
        }
      }
    }
    .slick-next {
      left: auto;
      right: 205px;
      z-index: 9;
      top: -85px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #E8E6CA;
      color: var(--main-color);
      padding: 18px;
      box-shadow: 0px 8px 20px rgba(7, 36, 95, 0.15);
      transition: all 0.2s linear;
      &:hover {
        background: var(--main-color);
        color: #E8E6CA;
      }
    }
    .slick-prev {
      left: auto;
      right: 125px;
      z-index: 9;
      top: -85px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #E8E6CA;
      color: var(--main-color);
      padding: 18px;
      box-shadow: 0px 8px 20px rgba(7, 36, 95, 0.15);
      transition: all 0.2s linear;
      &:hover {
        background: var(--main-color);
        color: #E8E6CA;
      }
    }
  }
}
.swiper-container {
  margin: -305px -93px 253px;
  transform: translate(0px, 134px);
  padding-top: 172px;
  .button {
    height: 55px;
    width: 55px;
    line-height: 54px;
    border-radius: 50%;
    background: #E8E6CA;
    border: 0;
    box-shadow: 0px 8px 20px rgba(7, 36, 95, 0.15);
    transition: $transition;
    text-align: center;
    position: absolute;
    top: 22px;
    left: auto;
    img {
      transition: $transition;
    }
    &:hover {
      background: var(--main-color);
      color: #E8E6CA;
      box-shadow: 0px 8px 19px rgba(255, 76, 19, 0.3);
      img {
        filter: brightness(99);
      }
    }
  }
  .swiper-button-prev {
    right: 158px;
  }
  .swiper-button-next {
    right: 93px;
  }
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms linear;
  transform: scale(1);
  &.swiper-slide-active {
    .single-portfolio-wrap {
      transform: scale(1.7);
      .portfolio-details {
        opacity: 1;
      }
    }
  }
  &.swiper-slide-next {
    z-index: -1;
  }
}
.single-portfolio-wrap {
  transition: $transition;
  margin: 84px 0;
  position: relative;
  .portfolio-details {
    position: absolute;
    left: 0;
    bottom: 0;
    width: -webkit-fill-available;
    background: #E8E6CA;
    border-radius: 5px;
    margin: 20px;
    padding: 20px 23px 16px 22px;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: $transition;
    .details {
      p {
        font-weight: 500;
        font-size: 10px;
        line-height: initial;
        color: var(--main-color);
        margin-bottom: 9px;
      }
      h4 {
        font-size: 17px;
        margin-bottom: 0;
      }
    }
    .icon {
      height: 28px;
      width: 28px;
      background: var(--main-color);
      border-radius: 5px;
      text-align: center;
      line-height: 30px;
      color: #E8E6CA;
      margin-left: auto;
      font-size: 12px;
    }
  }
}

/********partner area*********/
.partner-area-2 {
  background-size: 100% 100% !important;
  background-position: 0px 0px !important;
  background-repeat: no-repeat !important;
  position: relative;
  top: -109px;
  z-index: 1;
  margin-bottom: -109px;
}
.partner-slider {
  .thumb {
    cursor: pointer;
    &:hover {
      img {
        filter: grayscale(0);
      }
    }
    img {
      width: auto;
      margin: auto;
      filter: grayscale(1);
      transition: $transition;
    }
  }
  .owl-nav {
    display: none;
  }
}
/********skill area*********/
.skill-area {
  .section-title {
    margin-bottom: 19px;
  }
  .section-title .subtitle.style-2 {
    background-size: 95% 100%;
  }
  .thumb {
    position: relative;
    &.img-2 {
      margin-top: 90px;
    }
  }
}
.single-progressbar {
  .title {
    display: flex;
    margin-bottom: 7px;
    h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0;
    }
    .progress-count-wrap {
      margin-left: auto;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--heading-color);
      font-family: var(--heading-font);
    }
  }
}
.progress-item {
  margin-bottom: 28px;
  .progress-count-wrap {
    position: absolute;
    top: -35px;
    right: 16px;
  }
  .progress-count.counting {
    font-weight: 600;
    font-size: 16px;
    color: var(--heading-color);
    font-family: var(--heading-font);
  }
}
.progress-item.add-progress-start .progress-count-wrap {
  position: absolute;
  top: -35px;
  right: -5px;
}
.progress-item.add-progress-full .progress-count-wrap {
  position: absolute;
  top: -35px;
  right: 11px;
}
.progress-item .progress-bg {
  position: relative;
  height: 6px;
  border-radius: 3px;
  background: #e0e0e0;
}
.progress-item .progress-bg .progress-rate {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 3px;
  background: var(--main-color);
  transition: 2.5s;
}
/***********video-area***********/
.video-area {
  position: relative;
  z-index: 0;
  overflow: hidden;
  .video-thumb-wrap {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 80%;
    img {
      height: 100%;
    }
    .video-play-btn {
      position: absolute;
      top: 50%;
      left: 68.5%;
      animation: none;
      &:after {
        height: 100px;
        width: 100px;
        background: var(--main-color);
        opacity: 0.3;
        border: 0;
        left: -20px;
        top: -20px;
      }
    }
  }
  .cta-inner-wrap {
    position: relative;
    .section-title .subtitle.style-2 {
      background: url("~/public/assets/img/shape/bg-subtitle.png");
      background-size: 100% 100%;
      background-position: bottom;
      background-repeat: no-repeat;
    }
    .single-cta-wrap .icon {
      background: #E8E6CA;
      color: var(--main-color);
    }
    .single-cta-wrap .icon:after {
      background: #E8E6CA;
    }
    .single-cta-wrap .details h3 {
      color: #E8E6CA;
    }
    .btn {
      color: var(--main-color);
      &:after {
        background: var(--main-color);
      }
      &:hover {
        background: #E8E6CA;
        &:after {
          background: #E8E6CA;
        }
      }
    }
  }
}
/******contact area*******/
.contact-area {
  background: #E8E6CA;
  box-shadow: 0px 10px 50px rgba(8, 12, 36, 0.08);
  .contact-form {
    padding: 29px 40px 40px;
    h3 {
      border-bottom: 2px solid var(--main-color);
      margin-bottom: 40px;
      padding-bottom: 4px;
    }
  }
}
.contact-information-wrap {
  background: var(--main-color);
  padding: 29px 40px 40px;
  h3 {
    border-bottom: 2px solid #E8E6CA;
    margin-bottom: 40px;
    padding-bottom: 4px;
    color: #E8E6CA;
  }
  .single-contact-info-wrap {
    background: #fff;
    border-radius: 5px;
    padding: 23px 30px 27px;
    margin-bottom: 24px;
    h6 {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 9px;
    }
    .media {
      display: flex;
      .icon {
        flex: none;
        height: 50px;
        width: 50px;
        line-height: 50px;
        background: var(--main-color);
        border-radius: 5px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        font-size: 20px;
      }
      p {
        line-height: 26px;
        margin-bottom: 0;
      }
    }
  }
}

.contact-g-map {
  margin-bottom: -11px;
  iframe {
    line-height: 1 !important;
    border: 0;
    width: 100%;
    height: 600px;
  }
}
.request-quote-inner {
  background: #E8E6CA;
  box-shadow: 0px 10px 50px rgba(8, 12, 36, 0.1);
  position: relative;
  top: -168px;
  margin-bottom: -168px;
  .nav-pills {
    background: #161922;
    .nav-item {
      width: 50%;
      .nav-link {
        width: 100%;
        height: 80px;
        padding: 0;
        border-radius: 0;
        font-weight: 700;
        font-size: 16px;
        color: #E8E6CA;
        &.active {
          background: var(--main-color);
        }
        img {
          margin-right: 15px;
        }
      }
    }
  }
  .tab-content {
    .tab-pane {
      padding: 46px 50px 50px;
      .subtitle {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 14px;
      }
      .single-check-list {
        margin: 0 0 40px 0;
        padding: 0;
        list-style: none;
        li {
          display: inline-block;
          + li {
            margin-left: 30px;
          }
        }
      }
      .btn {
        height: 55px;
        line-height: 55px;
        font-size: 14px;
      }
    }
  }
}
.quote-wrap {
  padding: 31px 40px 40px;
  margin-left: 26px;
  h4 {
    color: #E8E6CA;
    line-height: 40px;
    margin-bottom: 17px;
  }
  p {
    color: #E8E6CA;
    line-height: 26px;
    margin-bottom: 48px;
  }
  .btn {
    height: 55px;
    line-height: 55px;
    color: var(--main-color);
    font-size: 14px;
    &:hover {
      background: var(--heading-color);
      color: #E8E6CA;
      &:after {
        background: var(--heading-color);
      }
    }
  }
}
/************single-pricing-wrap************/
.single-pricing-inner {
  background: #E8E6CA;
  box-shadow: 0px 6px 30px rgba(0, 15, 87, 0.08);
  padding: 44px 60px 50px;
  margin-bottom: 30px;
  .header {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 4px;
    margin-bottom: 26px;
    h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      display: inline-block;
      position: relative;
      margin-bottom: 17px;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -5px;
        height: 2px;
        width: 100%;
        background: var(--main-color);
      }
    }
    .price {
      h2 {
        font-weight: 900;
        font-size: 64px;
        line-height: 83px;
        color: var(--main-color);
      }
      sup {
        font-weight: 700;
        font-size: 36px;
        line-height: 47px;
        color: var(--main-color);
        font-family: var(--heading-font);
      }
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--heading-color);
        margin-left: -26px;
        position: relative;
        bottom: -5px;
      }
    }
  }
  .single-list-inner {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 38px;
    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      + li {
        margin-top: 18px;
      }
      i,
      svg {
        color: var(--main-color);
        margin-right: 10px;
      }
      &.unavailable {
        color: #d6d6d6;
        i,
        svg {
          color: #d6d6d6;
        }
      }
    }
  }
  .btn {
    height: 55px;
    line-height: 55px;
    font-size: 14px;
    background: var(--heading-color);
  }
}
/*******faq-accordion*******/
.faq-accordion {
  .single-accordion-inner {
    border: 0;
    margin-bottom: 30px;
    background: #E8E6CA;
    box-shadow: 0px 4px 20px rgba(8, 12, 36, 0.08);
    .accordion-header {
      .accordion-button {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: var(--heading-color);
        background: #E8E6CA;
        border: 0;
        padding: 10px 30px;
        box-shadow: none;
        &:not(.collapsed) {
          background: var(--main-color);
          color: #E8E6CA;
          span {
            color: #E8E6CA;
          }
          &:after {
            background: #E8E6CA;
          }
          &:before {
            display: none;
          }
        }
        span {
          color: var(--main-color);
          margin-right: 7px;
        }
        &:after {
          background: var(--main-color);
          height: 2px;
          width: 12px;
          background: var(--main-color);
          transform: none;
        }
        &:before {
          content: "";
          background: var(--main-color);
          height: 12px;
          width: 2px;
          position: absolute;
          right: 35px;
          transition: $transition;
        }
      }
    }
    .accordion-body {
      padding: 24px 30px;
    }
  }
}

// Support Bar

.support-bar {
  background-color: var(--button-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .support-left {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      li {
        display: inline-block;
        font-size: 14px;
        margin: 0 8px;
        svg {
          margin-right: 5px;
          font-size: 15px;
          color: #E8E6CA;
        }
        select {
          text-transform: none;
          background-color: transparent;
          border: none;
          color: var(--secondary-color);
          option {
            color: var(--main-color-2);
          }
        }
        &:first-child {
          margin-left: 0;
        }
        a {
          color: var(--bg-color);
          -webkit-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
          &:hover {
            color: var(--main-color);
          }
          i {
            padding-right: 7px;
            margin-left: 0px;
            &::before {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .support-right {
    float: right;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      li {
        display: inline-block;
        font-size: 14px;
        margin: 0 8px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          color: var(--bg-color);
          font-size: 14px;
          -webkit-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          &:hover {
            color: var(--main-color);
          }
          svg {
            margin-right: 7px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

/*------------------------------
    Solution Area
------------------------------*/
/*------------------------------
    Solution Area
------------------------------*/
.solution_area {
  background-color: var(--secondary-color);
  padding: 130px 0 100px;
  &.style-02 {
    padding: 130px 0 50px;
    // background: url(../img/bg/solution-bg.png) no-repeat center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .solution-item {
      margin-bottom: 30px;
    }
    .remove-col-padding {
      position: relative;
    }
    .solution-bg {
      background-size: cover;
      height: 100%;
      display: block;
      background-size: cover;
      height: 100%;
      display: block;
      z-index: 1;
      position: relative;
      .img-file {
        position: relative;
        z-index: 1;
        &:after {
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
          background: #ffdbdb 0% 0% no-repeat padding-box;
          opacity: 1;
          z-index: -1;
          content: "";
          right: 9%;
          top: 10%;
        }
      }
    }
  }
  &.style-03 {
    padding: 130px 0 50px;
    // background: url(../img/bg/solution-bg.png) no-repeat center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .solution-item {
      margin-bottom: 30px;
    }
    .remove-col-padding {
      position: relative;
    }
    .solution-bg {
      background-size: cover;
      height: 100%;
      display: block;
      background-size: cover;
      height: 100%;
      display: block;
      z-index: 1;
      position: relative;
      right: 100px;
      top: 45px;
      .img-file {
        position: relative;
        z-index: 1;
        &:after {
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
          background: #ffdbdb 0% 0% no-repeat padding-box;
          opacity: 1;
          z-index: -1;
          content: "";
          right: -10%;
          top: -10%;
        }
      }
    }
  }

  .section-title {
    .subtitles {
      color: var(--main-color);
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 10px;
      h2 {
        font-size: 40px;
      }
      p {
        color: var(--paragraph-color);
        margin-bottom: 40px;
      }
    }
  }
  .solution-item {
    .solution-image {
      margin-bottom: 30px;
    }
    h4 {
      font-weight: 600;
      font-size: 18px;
      color: var(--paragraph-color);
    }
    &:hover {
      .solution-image {
        img {
          opacity: 0.5;
        }
      }
      h4 {
        color: var(--main-color);
      }
    }
  }
}

// Feature section

.features-area-2 {
  padding: 150px 0;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    z-index: 0;
  }
  .container {
    z-index: 9;
    position: relative;
  }

  .features-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 65px 0px 0px;
    .single-features-item {
      padding: 60px 0px;
      width: 200px;
      height: 200px;
      background: #14448c;
      margin: 20px;
      text-align: center;
      transition: all 0.3s linear;
      &:hover {
        background-color: var(--main-color);
        border-radius: 50%;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }
      h4 {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        margin-left: 20px;
        color: var(--secondary-color);
        font-size: 20px;
        font-weight: normal;
      }
      .icon {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        text-align: center;
        padding-bottom: 10px;
        padding-right: 35px;
        svg {
          font-size: 30px;
          color: #E8E6CA;
        }
      }
    }
  }
  .section-title {
    padding-left: 20px;
    .subtitles {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #E8E6CA;
    }
    h2 {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 40px;
      font-weight: 900;
      position: relative;
      z-index: 0;
      color: var(--main-color);
    }
    p {
      color: #E8E6CA;
    }
    .btn-wrapper .btn-bounce {
      margin-top: 15px;
      background: var(--main-color);
      -webkit-box-shadow: 0px 6px 20px -8px var(--main-color);
      box-shadow: 0px 6px 20px -8px var(--main-color);
      color: var(--secondary-color);
      display: inline-block;
      padding: 12px 25px;
      min-width: 140px;
      text-align: center;
      text-transform: capitalize;
      position: relative;
      border: none;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      border-radius: 1px;
      text-decoration: none;
      opacity: 0.9;
      -webkit-transition: all 0.3s ease-in;
      transition: all 0.3s ease-in;
    }
  }
}

/*------------------------------
    Logistics Area
------------------------------*/
.logistics_area {
  padding: 80px 0;
  background: url("~/public/assets/img/bg/map-bg.png") no-repeat center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &.style-02 {
    padding: 130px 0 50px;
  }
  &.style-03 {
    padding: 110px 0 180px;
  }
  .logistics-image {
    background: url("~/public/assets/img/bg/logistics-bg.png") no-repeat center;
    height: 100%;
    display: block;
    background-size: 90%;
  }
  .logistics-content {
    margin-top: 80px;
  }
  .section-title {
    padding-left: 20px;
    .subtitles {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    h2 {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 40px;
      font-weight: 900;
    }
  }
  .counterup {
    padding: 10px 0px 130px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    .countr {
      text-align: left;
      padding: 20px 0px;
      .couter-icon {
        i {
          padding: 0px;
          color: var(--main-color);
          &::before {
            font-size: 50px;
          }
        }
        svg {
          padding: 0px;
          color: var(--main-color);
          font-size: 50px;
        }
      }
      .counter {
        font-size: 40px;
        line-height: 45px;
        font-weight: 700;
        color: var(--main-color);
        display: block;
        margin-bottom: 20px;
      }
      .title {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

/*------------------------------
    Shipment Area
------------------------------*/
.shipment-area {
  padding: 130px 0 130px;
  background-color: var(--main-color);
  &.style-02 {
    background-color: var(--main-color);
    .shipment-form-wrap {
      margin: 0px;
      margin-bottom: -210px;
      box-shadow: -2px 2px 2px 2px #eee;
    }
  }
  &.style-03 {
    background-color: #f5f5f5;
    .section-title {
      .title {
        color: var(--heading-color);
      }
      .sub-title {
        color: var(--paragraph-color);
      }
      .btn-wrapper {
        a {
          background: var(--main-color);
        }
      }
    }
  }

  .section-title {
    padding-left: 20px;
    .subtitles {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #E8E6CA;
    }
    h2 {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 40px;
      font-weight: 900;
      color: #E8E6CA;
    }
    p {
      color: #E8E6CA;
    }
  }

  .shipment-form-wrap {
    padding: 50px 30px;
    background-color: var(--secondary-color);
    margin-top: -210px;
    box-shadow: 0px -10px 10px #eee;
    h3 {
      color: var(--main-color);
      margin-bottom: 20px;
    }
    #shipment-form {
      h4 {
        font-weight: 700;
        color: var(--paragraph-color);
      }
      .weight {
        display: inline-flex;
        width: 100%;
        span {
          margin: 8px 12px 8px 0px;
        }
      }
      .shipment-process {
        margin: 20px 0;
        .form-group {
          .form-radio {
            padding: 0px 10px 0px 20px;
          }
          label {
            padding: 0px 10px 0px 5px;
          }
        }
      }
    }
    .form-group {
      width: 100%;
      select {
        margin-bottom: 30px !important;
      }
      .form-control {
        display: block;
        width: 100%;
        height: 50px;
        line-height: 1.5;
        color: #495057;
        background-color: #E8E6CA;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-bottom: 30px;
      }
      .shipment-btn {
        width: 100%;
        height: 50px;
        background-color: var(--main-color);
        color: #E8E6CA;
        display: inline-block;
        text-align: center;
        text-transform: capitalize;
        -webkit-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
        border: 1px solid transparent;
      }
    }
  }
  .btn-wrapper .boxed-btn {
    display: inline-block;
    padding: 10px 25px;
    min-width: 180px;
    text-align: center;
    background-color: #14448c;
    color: #E8E6CA;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    font-weight: 400;
  }
}

/*------------------------------
    Testimonial Area
------------------------------*/
.testimonial-area-3 {
  padding: 130px 0 110px;
  .review-area {
    &.style-01 {
      .slick-prev {
        display: none !important;
      }
    }
  }
  &.style-02 {
    padding: 120px 0 165px;
    .testimonial-slider-two {
      .slick-prev {
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        position: absolute;
        top: 50%;
        left: -60px;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;
        i {
          color: #E8E6CA;
          width: 40px;
          height: 40px;
          line-height: 40px;
          background-color: #f14b4b;
          border-radius: 50%;
          text-align: center;
          display: inline-block;
          border-radius: 50%;
          &:hover {
            color: #f14b4b;
            background-color: transparent;
            border: 2px solid #f14b4b;
          }
          &::before {
            font-size: 20px;
            display: contents;
          }
        }
      }
      .slick-next {
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        position: absolute;
        top: 50%;
        right: -60px;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;
        i {
          color: #E8E6CA;
          width: 40px;
          height: 40px;
          line-height: 40px;
          background-color: #f14b4b;
          border-radius: 50%;
          text-align: center;
          display: inline-block;
          border-radius: 50%;
          &:hover {
            color: #f14b4b;
            background-color: transparent;
            border: 2px solid #f14b4b;
          }
          &::before {
            font-size: 20px;
            display: contents;
          }
        }
      }

      .slick-dots {
        position: absolute;
        left: 50%;
        bottom: -60px;
        transform: translateX(-50%);
      }
    }
  }
  .section-title {
    padding-left: 20px;
    .subtitles {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      color: var(--main-color);
    }
    h2 {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 40px;
      font-weight: 900;
    }
  }
  .testimonial-slider {
    padding-bottom: 80px;
    height: 380px;
  }
  .single-review {
    padding: 5px 15px;
  }
  .single-review-item {
    padding: 10px 20px 10px;

    position: relative;
    box-shadow: 0px 0px 10px #0000001a;

    &.slick-current {
      box-shadow: 0px 0px 15px #0000001a;
    }
    .review-head {
      display: flex;
      .author-details {
        padding-left: 12px;
        margin-top: 7px;
        h5 {
          font-size: 16px;
          line-height: 16px;
          text-transform: capitalize;
          margin-top: 0;
        }
        .post {
          font-size: 13px;
          color: var(--main-color);
          font-weight: 700;
        }
        .rating {
          padding-left: 0 !important;
          display: flex;
          gap: 1px;
          li {
            display: inline-block;
            font-size: 14px;
            color: var(--main-color);
          }
        }
      }
      .thumb {
        left: 20px;
        img {
          border-radius: 5px;
        }
      }
    }

    .desciption {
      padding-top: 0px;
      p {
        color: #505050;
      }
    }
  }
  .slick-prev {
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    position: absolute;
    top: 50%;
    left: -60px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    i {
      color: #E8E6CA;
      width: 40px;
      height: 40px;
      line-height: 40px;
      background-color: #f14b4b;
      border-radius: 50%;
      text-align: center;
      display: inline-block;
      border-radius: 50%;
      &:hover {
        color: #f14b4b;
        background-color: transparent;
        border: 2px solid #f14b4b;
      }
      &::before {
        font-size: 20px;
        display: contents;
      }
    }
  }
  .slick-next {
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    position: absolute;
    top: 40%;
    right: -60px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    i {
      color: #E8E6CA;
      width: 40px;
      height: 40px;
      line-height: 40px;
      background-color: #f14b4b;
      border-radius: 50%;
      text-align: center;
      display: inline-block;
      border-radius: 50%;
      &:hover {
        color: #f14b4b;
        background-color: transparent;
        border: 2px solid #f14b4b;
      }
      &::before {
        font-size: 20px;
        display: contents;
      }
    }
  }

  .slick-dots {
    position: absolute;
    left: 12%;
    bottom: -70px;
    transform: translateX(-50%);
  }
  .slick-dots li {
    margin: 0 7px;
  }
  .slick-dots button {
    color: transparent;
    background: var(--paragraph-color);
    width: 15px;
    height: 15px;
    border: none;
    border-radius: 50%;
    opacity: 0.3;
    transition: all 0.3s linear;
    cursor: pointer;
    &::before {
      opacity: 0;
    }
  }
  .slick-dots .slick-active button {
    background: var(--main-color);
    width: 25px;
    height: 15px;
    border-radius: 5px;
    opacity: 1;
    &::before {
      opacity: 0;
    }
  }
  .slick-dots:hover button {
    &::before {
      opacity: 0;
    }
  }
}

/*--------------------------------------------------------------
	## Company
--------------------------------------------------------------*/
.company-area {
  padding: 80px 0;
  background-color: rgba(#14448c, 1);
  position: relative;
  .company-carousel {
    .single-carousel-item {
      display: inline-block;
      padding: 0 10px;
      a {
        display: flex;
        justify-content: center;
      }
      &:hover {
        img {
          filter: grayscale(0);
        }
      }
      img {
        filter: grayscale(100%);
      }
    }
  }
}

/*------------------------------
    transport-service Page
------------------------------*/
.transport-service-area {
  padding: 130px 0px 130px;
  background: var(--secondary-color);
  &.style-03 {
    padding: 130px 0px 100px;
    background: var(--button-color);
    .title {
      color: var(--secondary-color);
    }
    .sub-title {
      color: var(--secondary-color);
      font-size: 15px;
    }
    .single-service-item {
      margin-bottom: 30px;
      padding: 15px;
      border-radius: 5px;
      cursor: pointer;
      h3 {
        margin-top: 20px;
        font-size: 26px;
        color: var(--secondary-color);
      }
      p {
        font-size: 15px;
        color: var(--bg-color);
      }
      &:hover {
        transition: all 0.2s ease-in-out;
        background: #E8E6CA31;
      }
    }
  }
}
.transport-service-single {
  margin-bottom: 70px;
  display: flex;
  &:hover {
    .transport-service-content:before {
      background: var(--main-color);
    }
  }
  .transport-service-content {
    margin: auto;
    padding-left: 25px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      height: 245px;
      z-index: 9;
      width: 5px;
      top: auto;
      left: 0px;
      background: var(--main-color);
      display: block;
    }
    h3 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 30px;
    }
    span {
      font-weight: 600;
      color: var(--heading-color);
    }
  }
  .btn-wrapper .boxed-btn {
    display: inline-block;
    padding: 10px 25px;
    min-width: 180px;
    text-align: center;
    background-color: var(--main-color);
    color: #E8E6CA;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    font-weight: 400;
  }
}

.btn-wrapper .btn-bounce {
  background: var(--main-color);
  -webkit-box-shadow: 0px 6px 20px -8px #E8E6CA;
  box-shadow: 0px 6px 20px -8px var(--main-color);
  color: #E8E6CA;
  display: inline-block;
  padding: 12px 25px;
  min-width: 140px;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  border: none;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  border-radius: 1px;
  text-decoration: none;
  opacity: 0.9;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.transport-service-image {
  position: relative;
  display: inline-block;
  .transport-service-item-hover {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    .transport-service-link {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      top: calc(50% - 15px);
      transform: translateY(-50%);
      transition: all 0.5s ease;
      // visibility: hidden;
      opacity: 0;
      z-index: 9;
      width: 30px;
      height: 30px;
      // left: calc(50% - 15px);
      left: calc(50% - 0px);
      transition-delay: 0.3s;
      span {
        color: #E8E6CA;
        width: 100%;
        height: 100%;
        line-height: 50px;
        display: block;
        font-size: 18px;
        &::before {
          font-size: 30px;
        }
      }
    }

    &:before {
      position: absolute;
      left: -50%;
      top: -50%;
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1;
      opacity: 0;
      transition: all 0.5s ease;
    }
    &:after {
      position: absolute;
      right: -50%;
      bottom: -50%;
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1;
      opacity: 0;
      transition: all 0.5s ease;
    }
  }
  &:hover .transport-service-item-hover:before {
    opacity: 1;
    left: 0;
    top: 0;
  }
  &:hover .transport-service-item-hover:after {
    opacity: 1;
    right: 0;
    bottom: 0;
  }
  &:hover .transport-service-link {
    opacity: 1;
    left: calc(50% - 15px);
  }
}

/*------------------------------
    FeaturesArea
------------------------------*/
.features-area {
  padding: 130px 0 130px;
  // background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  //   url(../img/bg/feature-bg.png) no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  &.style-02 {
    background: var(--button-color);
    padding: 110px 0 85px;
    .subtitles {
      color: #E8E6CA;
    }
    .title {
      color: #E8E6CA;
    }
    .des {
      color: #E8E6CA;
    }
    .features-item {
      margin-top: 0px;
      transform: rotate(0deg);
      display: flex;
      .single-features-item {
        margin: auto;
        padding: 90px 10px;
        width: 240px;
        height: 240px;
        margin-bottom: 50px;
        text-align: center;
        background-color: var(--secondary-color);
        transition: all 0.4s linear;
        .icon {
          padding: 0;
          svg {
            font-size: 60px;
            color: var(--main-color);
          }
        }
        h4 {
          color: var(--main-color);
          font-size: 22px;
          transform: rotate(0deg);
        }

        &:hover {
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
            0 10px 10px rgba(0, 0, 0, 0.22);
          transform: rotate(0deg);
          border-radius: 0%;
          .icon {
            padding: 0;
            svg {
              font-size: 60px;
              color: var(--secondary-color);
            }
          }
          h4 {
            color: #E8E6CA;
          }
        }
        .icon {
          text-align: center;
          margin: auto;
          transform: rotate(0deg);
          i {
            color: var(--active-color);
            &::before {
              font-size: 50px;
            }
          }
        }
      }
    }
  }
  .features-item {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    transform: rotate(45deg);
    margin: 150px 0px 0px;
    .single-features-item {
      margin: auto;
      width: 250px;
      height: 250px;
      margin: 20px;
      text-align: center;
      background-color: var(--button-color);
      &:hover {
        background-color: var(--active-color);
        border-radius: 50%;
        transform: rotate(0deg);
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
        .icon {
          i {
            color: var(--secondary-color);
          }
        }
      }
      .icon {
        transform: rotate(-45deg);
        text-align: center;
        padding-bottom: 10px;
        padding-right: 45px;
        i {
          color: var(--active-color);
          &::before {
            font-size: 50px;
          }
        }
      }
      h4 {
        transform: rotate(-45deg);
        margin-left: 20px;
        color: var(--secondary-color);
      }
    }
  }
}
