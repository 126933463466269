/* Main Navbar Container */
.navbar-unique-area {
  background-color: #2c3e50;
  color: #fff;
  font-family: Arial, sans-serif;
}
.navbar-unique-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Top Bar */
.navbar-unique-top {
  background-color: #1a2734;
  padding: 10px 0;
}

.navbar-unique-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  flex-wrap: nowrap;
}

/* Contact Icon and Info */
.navbar-contact-icon {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 250px;
}

.navbar-logo-icon {
  width: 22px;
  height: 22px;
}

.navbar-phone-icon {
  color: #fa4318;
}

.navbar-contact-number {
  font-size: 14px;
  color: #ddd;
  white-space: nowrap;
}

/* Address Info */
.navbar-contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  color: #ddd;
}

.navbar-info-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.navbar-info-item {
  margin: 0 5px;
}

.navbar-clock-icon {
  color: #fa4318;
  margin-right: 5px;
}

/* Social Links */
.navbar-social-links {
  display: flex;
  justify-content: flex-end;
  min-width: 150px;
}

.social-icons {
  list-style: none;
  display: flex;
  gap: 10px;
  margin: 0;
}

.social-icons a {
  color: #ddd;
  font-size: 14px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #fa4318;
}

/* Navbar */
.navbar-unique {
  background-color: #244855;
  padding: 15px 0;
  border-top: 2px solid #fa4318;
}

.sticky-active {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-unique-logo {
  display: flex;
  align-items: center;
}

.navbar-unique-title {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  margin: 0;
}

/* Toggle Button */
.navbar-toggle {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  outline: none;
}

.navbar-toggle-icon {
  width: 24px;
  height: 3px;
  background-color: #fff;
  transition: transform 0.3s;
}

/* Navbar Links */
.navbar-unique-nav {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
}

.navbar-unique-nav li a {
  color: #ddd;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 8px 12px;
  transition: color 0.3s, background 0.3s;
  border-radius: 4px;
}

.navbar-unique-nav li a:hover {
  color: #fff;
  background-color: #fa4318;
}
.navbar-toggle {
  display: none;
}
.nav-sm {
  display: none;
  background-color: #244855 !important;
}
.navbar-open {
    display: block !important;
  }
  
/* Responsive */
@media (max-width: 768px) {
  .navbar-unique-container {
    align-items: center;
  }
  .navbar-contact-info {
    display: none;
  }
  .navbar-contact-icon,
  .navbar-contact-info,
  .navbar-social-links {
    margin: 5px 0;
    text-align: center;
  }

  .navbar-logo-icon {
    width: 18px;
    height: 18px;
  }

  .navbar-unique-title {
    font-size: 20px;
  }

  .navbar-info-list {
    font-size: 12px;
  }

  .navbar-unique-nav {
    flex-direction: column;
    align-items: center;
    gap: 10px;
   
    padding: 10px 0;
  }

  .navbar-unique-menu {
    display: none;
  }

  
  .navbar-toggle {
    display: flex;
  }
 
}
