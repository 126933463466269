
.button-11 {
    background-color: #fa4318;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    padding: 6px 30px;
    border-radius: 50px;
    border: 2px solid white;
    transition: all 0.3s ease-in-out;
  }

  .address-form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    margin: 0 auto;
  }
  .form-control{
    font-size:10px !important
  }
  .error-text{
    font-size:12px !important
  }
  .form-title {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .form-description {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 20px;
  }
 
  .form-actions {
    display: flex;
    justify-content: flex-end;
  }
  .error-text {
    color: red;
    font-size: 0.875rem;
  }
  @media (max-width: 768px) {
    .form-actions {
      justify-content: center;
    }
    .origin-preferences-form {
      padding: 15px;
    }
  }
  .contact-form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 1100px;
    margin: 0 auto;
  }

  .carrier-equipment-form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;
  }

  .origin-preferences-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    margin: 0 auto;
  }
  .origin-preferences-form h4 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .origin-preferences-form .form-group {
    margin-bottom: 20px;
  }
  .origin-preferences-form p {
    margin-top: 10px;
  }
  .origin-preferences-form .btn-link {
    padding-left: 0;
    padding-right: 0;
    margin-left: 5px;
  }
