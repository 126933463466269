/* LogisticsPage.css */

.logistics-page-container-11 {
  padding: 30px 80px;
  background-color: #f5f5f5;
}

.title {
  color: #fa4318;
  font-size: 2rem;
  font-weight: bold;
}

.description {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1rem;
}

.custom-blockquote {
  font-style: italic;
  color: #666;
  border-left: 4px solid #fa4318;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.image-container {
  text-align: right;
}

.responsive-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .logistics-page-container-11 {
    padding: 30px 10px;
   
  }
  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.9rem;
  }
}
