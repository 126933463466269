.carrier-benefits-section {
    /* background-color: white; */
    padding: 60px 0;
  }
  
  .carrier-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
  }
  
  .carrier-subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 40px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .benefit-item {
    margin-bottom: 40px;
    border: 2px solid #E68433;
    padding: 25px 0;
  }
  
  .benefit-icon {
    font-size: 50px;
    color: #fa4318; /* Your theme color */
    margin-bottom: 20px;
  }
  
  .benefit-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
  }
  
  .benefit-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  .download-link {
    color: #fa4318;
    font-weight: 500;
    text-decoration: none;
  }
  
  .download-link:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .carrier-title {
      font-size: 2rem;
    }
  
    .carrier-subtitle {
      font-size: 1rem;
    }
  
    .benefit-title {
      font-size: 1.3rem;
    }
  
    .benefit-description {
      font-size: 0.9rem;
    }
  }
  