.form-container {
    max-width: 700px; /* Adjust this value to control the width */
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .form-container {
      max-width: 100%;
      padding: 10px;
    }
  }
  
  @media (min-width: 992px) {
    .form-container {
      max-width: 600px;
    }
  }
/* Styling for Tabs */
section {
    padding: 60px 0;
}

.section-title {
    /* text-align: center; */
    /* color: #007b5e; */
    margin-bottom: 50px;
    text-transform: uppercase;
}

#tabs {
    /* background: #007b5e; */
    color: black;
}

#tabs h6.section-title {
    color: black;
}

#tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: black;
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    border-bottom: 4px solid !important;
    font-size: 20px;
    font-weight: bold;
}

#tabs .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #fa4318;
    font-size: 20px;
}

#tabs .tab-content {
    background: #fff;
    padding: 20px;
    /* border: 1px solid #ddd; */
    border-top: none;
}
  